
.display-none{ display: none; }
.display-contents{ display: contents; }

.text-a-r{ text-align: right !important; }
.text-a-l{ text-align: left !important; }
.text-a-c{ text-align: center !important; }

.text-light-gray{ color: #a0a0a0 !important; }
.text-black{ color: #000000 !important; }


.border-left{ border-left: 1px solid #e5e5e554; }


.gap-1 { gap: 0.5rem!important;}
.gap-2 { gap: 0.7rem!important;}
.gap-3 { gap: 1rem!important;}
.gap-4 { gap: 1.3rem!important;}
.gap-5 { gap: 1.5rem!important;}
.gap-6 { gap: 2rem!important;}



footer.page-footer {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-top: unset !important;
}

.modal .modal-header, .modal .modal-footer {
  padding: 1rem !important;
}

.form-control, .bootstrap-tagsinput {
  padding: 0.8rem 0.75rem 0.5rem 0.75rem !important;
}

.menu .main-menu .new-menu li.active-li span.badge {
  color: #ffffff !important;
}

.menu .main-menu .new-menu li span.badge {
  float: right;
  margin-top: 9px;
}

.active-li .badge-light {
  background-color: #175387 !important;
}



.navbar .menu-button svg {
  height: 14px !important;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3rem 0.8rem 0.3rem 0.8rem !important;
}

.aus_flag-right{
  top: 13px;
  position: absolute;
  right: 20px;
}
.browser_left{
  top: 13px;
  position: absolute;
  left: 20px;
}
.signinbtn-section {
  text-align: center;
  margin-top: 3rem !important;
}
.login-section .auth-card {
  box-shadow: 0 1px 15px rgb(205 205 205 / 50%), 0 1px 6px rgb(220 239 255 / 50%);
}
@media (min-width: 1440px) and (max-width: 1935px) {
  .login-form{
    flex: 0 0 33%!important;
    max-width: 33%!important;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .login-form{
    flex: 0 0 40%!important;
    max-width: 40%!important;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .login-form{
    flex: 0 0 45%!important;
    max-width: 45%!important;
  }
}

@media (min-width: 768px) and (max-width: 989px) {
  .login-form{
    flex: 0 0 70%!important;
    max-width: 70%!important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .login-form{
    flex: 0 0 76%!important;
    max-width: 76%!important;
  }
}

@media all and (max-width: 575px) {
  .login-form{
    //flex: 0 0 76%!important;
    min-width: 360px!important;
  }
}


.login-section{
  .card {
    border: initial;
    background: #ffffffd4;
    backdrop-filter: blur(3px);
  }
}

.img-res-cover{
  width: 100%;
  height: 210px;
  object-fit: cover;
}


.list-view-header-img-res-cover{
  width: 100%;
  height: 263px;
  object-fit: cover;
}

//property tile View Responsive css
@media (min-width: 1900px){
  .img-res-cover{
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
  .list-view-header-img-res-cover{
    width: 100%;
    height: 263px;
    object-fit: cover;
  }
}

@media (max-width: 1700px) {
  .img-res-cover{
    width: 100%;
    height: 167px;
    object-fit: cover;
  }
  .property-tile {
    h5{
      font-size: 0.8rem!important;
    }

    p{
      font-size: 0.75rem!important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1305px) {
  .img-res-cover{
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .property-tile {
    h5{
      font-size: 0.7rem!important;
    }

    p{
      font-size: 0.7rem!important;
    }
  }
}

@media (max-width: 1200px){
  .property-tile {
    h5{
      font-size: 0.8rem!important;
    }

    p{
      font-size: 0.75rem!important;
    }
  }
}
@media (max-width: 767px){
  .img-res-cover{
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .property-tile {
    h5{
      font-size: 0.95rem!important;
    }

    p{
      font-size: 0.85rem!important;
    }
  }
}

.watermark {
  position: relative;
}


.f-12{font-size: 12px;}
.f-14{font-size: 14px;}
.f-15{font-size: 15px;}
.f-16{font-size: 16px;}
.f-17{font-size: 17px;}
.f-18{font-size: 18px;}
.f-19{font-size: 19px;}
.f-20{font-size: 20px;}


@media (min-width: 992px) and(max-width: 1199px) {
  .list-view-header-img-res-cover{
    width: 100%;
    height: 397px;
    object-fit: cover;
  }

  .details-card{
    min-height: 251px;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.custom-input-group-append-icon{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0 !important;
}

has-left-icon-append .input-group-text{
  border-left: none !important;
}

has-right-icon-append .input-group-text{
  border-left: none !important;
}

has-both-icon-append .input-group-text{
  border-right: none !important;
  border-left: none !important;
}

.has-left-icon-append{
  .input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
    border-left: none;
    padding-left: 0px!important;
  }
}

.has-right-icon-append {.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-right: none!important;;
}}

.has-both-side-icon-append {.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-right: none;
  border-left: none;
  padding-left: 0px!important;
}}
.input-group-text-right-icon{
  border-left: none!important;;
}

.custom-input-group-append-icon-left{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0 !important;
}

.custom-input-group-append-icon-right{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0 !important;
}

.has-right-icon-append {
  .ng-select.ng-select-single .ng-select-container {
    border-right: none !important;
  }
}

.form-control, .bootstrap-tagsinput {
  max-height: 38.8px!important;
}

.w-91-5{
  width: 91.5%;
}

//.sell-my-property-btn-addon{
//  width: 88%;
//}
//@media (min-width: 1668px) and (max-width: 1856px) {
//  .sell-my-property-btn-addon{
//    width: 86%;
//  }
//}
//@media (min-width: 1592px) and (max-width: 1667px) {
//  .sell-my-property-btn-addon{
//    width: 85%;
//  }
//}
//@media (min-width: 1525px) and (max-width: 1592px) {
//  .sell-my-property-btn-addon{
//    width: 84%;
//  }
//}
//@media (min-width: 1467px) and (max-width: 1524px) {
//  .sell-my-property-btn-addon{
//    width: 83%;
//  }
//}
//@media (min-width: 1212px) and (max-width: 1466px) {
//  .sell-my-property-btn-addon{
//    width: 82%;
//  }
//}
//@media (min-width: 1200px) and (max-width: 1211px) {
//  .sell-my-property-btn-addon{
//    width: 81%;
//  }
//}
//
//@media (min-width: 1190px) and (max-width: 1199px) {
//  .sell-my-property-btn-addon{
//    width: 88%;
//  }
//}
//@media (min-width: 1122px) and (max-width: 1189px) {
//  .sell-my-property-btn-addon{
//    width: 87%;
//  }
//}
//
//@media (min-width: 1063px) and (max-width: 1121px) {
//  .sell-my-property-btn-addon{
//    width: 86%;
//  }
//}
//
//@media (min-width: 1013px) and (max-width: 1062px) {
//  .sell-my-property-btn-addon{
//    width: 85%;
//  }
//}
//
//@media (min-width: 900px) and (max-width: 1012px) {
//  .sell-my-property-btn-addon{
//    width: 85%;
//  }
//}

@media (min-width: 992px) and (max-width: 1200px) {
  .w-91-5{
    width: 92%;
  }

  .w-91 {
    width: 92%;
  }
  .w-90{
    width: 92%!important;

  }

}

@media (max-width: 991px) {
  .w-91-5 {
    width: 93.5%;
  }
  .w-91 {
    width: 92.7%;
  }
  .w-90{
    width: 83%!important;

  }

}

@media (max-width: 519px) {
  .w-91-5 {
    width: 93.1%;
  }
  .w-91 {
    width: 92%;
  }

}

@media (min-width: 360px) and (max-width: 430px) {
  .w-91-5 {
    width: 91%!important;
  }

  .w-91 {
    width: 90%;
  }

}

.text-area .form-control, .bootstrap-tagsinput {
  height: auto!important;
}

.img-box{
  height: 80px!important;
  width: 80px!important;
}

.img-responsive{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.border-right-radius-0{
  border-top-right-radius: 0px ;
  border-bottom-right-radius: 0px ;
}
.border-left-radius-0{
  border-top-left-radius: 0px ;
  border-bottom-left-radius: 0px ;
}
.w-60{
  width: 60%;
}

@media (min-width: 300px)and (max-width: 900px) {
  .banner .w-60{
    width: 100% !important;
  }
}

@media (min-width: 901px)and (max-width: 1200px) {
  .banner .w-60{
    width: 80% !important;
  }
}

@media (min-width: 1201px) {
  .banner .w-60{
    width: 60%;
  }
}

.message-box {
  border: solid 1px #000000;
  border-radius: 5px;
  //width: 70%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
}
.message-box-image{
  width: 150px;
  height: 170px;
  object-fit: cover;
}

.w-91 {
  width: 91%;
}

.w-90 {
  width: 90%;
}

.text-area .form-control, .bootstrap-tagsinput {
  max-height: unset !important;
}

.question-icon {
  width: 24px;
  height: 24px;
}

.custom-p-select {
  width: 100px !important;
}
.pagination .ng-select .ng-select-container {
  border-radius: 25px !important;
}

.br-25{
  border-radius: 25px !important;
}

.br-25 .ng-select .ng-select-container {
  border-radius: 25px !important;
}

.br-front-0{
  border-radius: 0 25px 25px 0 !important;
}

//@media (min-width: 1200px) {
//  .modal-xl {
//    max-width: 85% !important;
//  }
//}

@media print{
  .doNotPrint{display:none !important;}
}

.custom-list {
  a {
    pointer-events: none !important;
  }
  .text-info {
    color: #155a94 !important;
    font-weight: 600 !important;
  }
  .th-b-color {
    font-size: 0.875rem !important;
  }
  tbody {
    tr {
      td {
        font-size: 0.875rem !important;
      }
    }
  }
  .missing-data {
    pointer-events: visible !important;
  }
}

.navbar .navbar-left {
  height: 65px !important;
}

@media (max-width: 1439px){
  .navbar {
    height: 60px !important;
  }
  .navbar .menu-button {
    width: unset !important;
  }
  .menu {
    padding-top: 60px !important;
    height: calc(100% - 60px) !important;
  }
  .main-hidden .main-menu, .menu-hidden .main-menu {
    transform: translateX(-265px) !important;
  }
}

//@media (max-width: 1900px){
//.register-card{
//  width:650px !important;
//}
//}
//
//@media (max-width: 1439px){
//  .register-card{
//    width:650px !important;
//  }
//}
//
//
//@media (max-width: 1199px){
//  .register-card{
//    width:650px !important;
//  }
//
//  .main-card{
//    margin-left: -130px!important;
//  }
//}
//
//@media (max-width: 991px){
//  .register-card{
//    width:650px !important;
//  }
//
//  .main-card{
//    margin-left: -200px!important;
//  }
//}
//
//@media (min-width: 767px) and (max-width: 991px){
//  .register-card{
//    width:500px !important;
//  }
//  //
//  //.main-card{
//  //  margin-left: -200px!important;
//  //}
//}


/*New style*/

.desktop{
  .menu-collaps, .menu-button{
    display: none !important;
  }
}

.tablet {
  .sub-menu .submenu-label {
    display: none !important;
  }
  //.menu-default .sub-menu {
  //  width: 78px !important;
  //}
  .menu-default {
    padding-top: 15px !important;
  }

  .menu-default main {
    margin-left: 283px !important;
  }
  .menu-default.sub-hidden main {
    margin-left: 112px !important;
  }
  .menu-collaps, .menu-button{
    display: none !important;
  }
  .navbar .navbar-left {
    width: unset !important;
  }
  .navbar-right-section {
    width: calc(100% - 152px) !important;
  }
}

.menu-mobile {
  .main-show-temporary {
    .sub-menu .submenu-label {
      display: none !important;
    }
  }

  //.menu-default .sub-menu{
  //  width: 78px !important;
  //}

  @media (max-width: 767px) {
    .main-show-temporary .sub-menu {
      transform: unset !important;
    }
  }

  .menu-collaps{
    display: block !important;
    margin-right: 10px !important;
  }
  .menu-collaps.ml-4{
    margin-left: unset !important;
  }

  .navbar .navbar-left {
    width: unset !important;
  }
  .navbar-right-section {
    width: calc(100% - 134px) !important;
  }

  .mobile-remove-subs{
    display:  none !important;
  }
}


.sell-property {
  font-size: 13px;
  .ng-select.ng-select-single .ng-select-container {
    height: 31px !important;
  }
  .ng-select .ng-select-container {
    font-size: 0.8rem;
    padding-top: 2px;
    min-height: 30px !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding-top: 0px !important;
  }

  .form-control{
    font-size: 0.8rem;
    padding: 11px 0.75rem 0.5rem 0.75rem !important;
    /* height: initial; */
    height: 30px;
  }
  .addon-btn-height{
    height: 30px!important;
  }
}


/* iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* Your styles for iPad portrait here */
  div, p, span, h1, h2, h3, h4, h5, h6, a, label, input, select, textarea, button, table thead tr th, table td {
    font-size: 10px !important;
  }
}

.simple-notification-wrapper.bottom {
  z-index: 1500;
}


/**** Styles for Classic Mood ****/
.zoomed-in {
  transform: scale(0.6);  /* 60% zoom-out */
  transform-origin: top left;  /* Make sure it scales from the top-left corner */
  width: 166.67%;  /* Compensate for the shrinking caused by scaling */
  //height: 1492px;
  col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .modal-xl {
    max-width: 1000px;
  }
  @media (min-width: 576px) {
    .modal-lg {
      max-width: 800px;
    }

    col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }

    .w-92-prec{
      width: 95% !important;
    }

    .supplier-type{
      width: 95% !important;
    }

    .other-info{
      .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    .w-86{
      width: 95% !important;
      max-width: 95% !important;
    }

    .knowledge {
      .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }
      .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }

    .supplier{
      .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }

      .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }


    .eofy {
      .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
      }
      .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }

    .eofy-exp {
      .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }

      .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    .comp{
      .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }

      .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

  }


  .rental .w-91 {
    width: 95% !important;
  }

  .asset .w-91{
    width: 95% !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .w-90-prec{
      width: 90% !important;
    }
  }

  w-90{
    width: 90%;
  }

  .depreciation{
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (max-width: 1199px) {
    .menu .main-menu {
      height: calc(100% + 730px) !important;
    }
    .menu .sub-menu {
      height: calc(100% + 730px) !important;
    }
  }

  .input-group select{
    height: 38.8px !important;
  }

  time.icon span {
    font-size: 1.7em;
    letter-spacing: -0.05em;
    padding-top: 0.8em!important;
  }
}

/*Styles for Classic Mood End**/


